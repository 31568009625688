/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { silentAuth } from '~utils/auth';

import { createStore } from '~state/ReduxWrapper';

import '~styles/global.scss';

class SessionCheck extends Component {
  state = {
    loading: true,
  }

  handleCheckSession = () => {
    this.setState({ loading: false });
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession);
  }

  render() {
    return (
      this.state.loading === false && (
        <>{this.props.children}</>
      )
    );
  }
}

export const wrapRootElement = ({ element }) => {
  return <Provider store={createStore()}><SessionCheck><div>{element}</div></SessionCheck></Provider>
}
