const SEARCH_UPDATE_QUERY = 'SEARCH_UPDATE_QUERY';
const SEARCH_UPDATE_RESULTS = 'SEARCH_UPDATE_RESULTS';

const initialState = {
  searchQuery: '',
  searchResults: [],
};

// Action creators.
export const searchUpdateQuery = query => ({
  type: SEARCH_UPDATE_QUERY,
  query,
});

export const searchUpdateResults = results => ({
  type: SEARCH_UPDATE_RESULTS,
  results,
});
// :END: Action creators.

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_UPDATE_QUERY:
      return { ...state, searchQuery: action.query };

    case SEARCH_UPDATE_RESULTS:
      return { ...state, searchResults: action.results };

    default:
      return state;

  }
};
