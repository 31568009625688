const HEADER_UPDATE_HEIGHT = 'HEADER_UPDATE_HEIGHT';

const initialState = {
  headerHeight: 0,
};

// Action creators.
export const headerUpdateHeight = height => ({
  type: HEADER_UPDATE_HEIGHT,
  height,
});
// :END: Action creators.

export default (state = initialState, action) => {
  switch (action.type) {
    case HEADER_UPDATE_HEIGHT:
      return { ...state, headerHeight: action.height };

    default:
      return state;

  }
};
