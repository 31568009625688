import { navigate } from 'gatsby';
import auth0js from 'auth0-js';

export const isBrowser = typeof window !== 'undefined';

// To speed things up, we’ll keep the profile stored unless the user logs out.
// This prevents a flicker while the HTTP round-trip completes.
let profile = false;

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false
};

// Only instantiate Auth0 if we’re in the browser.
const auth0 = isBrowser
  ? new auth0js.WebAuth({
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    clientID: process.env.GATSBY_AUTH0_CLIENTID,
    redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
    // audience: process.env.GATSBY_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid profile email'
  })
  : {};

export const login = (redirectPath = false) => {
  if (!isBrowser) {
    return;
  }

  auth0.authorize({
    appState: {
      redirectPath,
    },
    helpText: "To go back to the previous page, please press the back arrow twice."
  });
};

export const logout = () => {
  localStorage.setItem('isLoggedIn', false);
  profile = false;

  const { protocol, host } = window.location;
  const returnTo = `${protocol}//${host}`;

  auth0.logout({ returnTo });
};

const setSession = callback => (err, authResult) => {
  if (!isBrowser) {
    return;
  }

  if (err) {
    console.error(err);
    callback();
    return;
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    tokens.accessToken = authResult.accessToken;
    tokens.idToken = authResult.idToken;
    tokens.expiresAt = expiresAt;
    profile = authResult.idTokenPayload;

    if (!isAuthenticated()) {
      auth0.checkSession({
          audience: `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/`,
          scope: 'read:current_user'
        }, function(err, result) {
          if (err) {
            console.error(err);
            return;
          }
          (new auth0js.Management({
            domain: process.env.GATSBY_AUTH0_DOMAIN,
            token: result.accessToken,
          })).getUser(authResult.idTokenPayload.sub, (err, user) => {
            if (err) {
              console.error(err);
              return;
            }
            if (user.logins_count <= 1) {
              navigate('/register/thank-you');
            }
          });
        }
      );
    }

    localStorage.setItem('isLoggedIn', true);

    if (authResult.appState.redirectPath) {
      navigate(`${authResult.appState.redirectPath}`);
    }

    callback();
  }
};

export const silentAuth = callback => {
  if (!isBrowser) {
    return;
  }

  if (!isAuthenticated()) return callback();
  auth0.checkSession({}, setSession(callback));
};

export const handleAuthentication = (callback = () => {}) => {
  if (!isBrowser) {
    return;
  }

  auth0.parseHash(setSession(callback));
};

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem('isLoggedIn') === 'true';
};

export const getAccessToken = () => {
  if (!isBrowser) {
    return '';
  }
  // The "idToken" is actually the JWT token required to the "authorization"
  // header value to authicate on the Node.js backend.
  return tokens.idToken;
};

export const getUserInfo = () => {
  if (profile) {
    return profile;
  }
};
